<template>
    <div class="Event_box">
        <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header ref="Header"></Header>
        <div class="event_cont">
            <div class="event_top">
                <div class="search">
                    <!-- 有热门搜索词时，不显示提示文案 -->
                    <template v-if="hotKeywords.length">
                        <input type="text" class="search_ipt" 
                        @focus="getkey"
                        v-model="keywords"
                        @keyup.enter="searchList"
                        >
                    </template>
                    <!-- 无搜索词时 -->
                    <template v-else>
                        <input type="text" class="search_ipt" 
                        @focus="getkey"
                        v-model="keywords"
                        @keyup.enter="searchList"
                        placeholder="请输入您想搜索的内容"
                        >
                    </template>
                    <span class="search_icon" @click="searchList">
                    </span>
                    <template v-if="hotKeywords.length">
                        <el-carousel
                        v-show="isHotkey"
                        @change="changehotkey"
                        direction="vertical"
                        :interval="3000"
                        indicator-position="none"
                        class="hotwords">
                            <el-carousel-item v-for="(item,index) in hotKeywords" :key="index">
                                <span class="key" @click="clickHotkey">{{item.keywords}}</span>
                            </el-carousel-item>
                        </el-carousel>
                    </template>
                   
                </div>
                <div class="event_nav">
                    <div class="nav_item">
                        <span class="cont_title">区域：</span>
                        <el-radio-group class="" 
                        :style="isSlide?'height: auto;':'height: 43px;overflow: hidden;'"
                        v-model="area"  @change="changeBtn">
                            <el-radio-button v-for="(area,index) in areas"  :key="index" :label="area">{{area}}</el-radio-button>
                        </el-radio-group>
                        <div class="more cleafix" @click="slideDown">
                            <template v-if="isSlide == false">
                                <span class="more_txt f_left">更多</span>
                                <i class="icon f_left"></i>
                            </template>
                            <template v-else>
                                <span class="more_txt f_left">收起</span>
                                <i class="icon icon2 f_left"></i>
                            </template>
                        </div>
                    </div>
                    <div class="nav_item">
                        <span class="cont_title">项目：</span>
                        <el-radio-group class="" v-model="type"  @change="changeBtn">
                            <el-radio-button v-for="(type,index) in types"  :key="index" :label="type">{{type}}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="nav_item">
                        <span class="cont_title ">年份：</span>
                        <el-radio-group class="" v-model="year" @change="changeBtn">
                            <el-radio-button v-for="(year,index) in years"  :key="index" :label="year">{{year}}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="nav_item">
                        <span class="cont_title ">赛况：</span>
                        <el-radio-group class="" v-model="state" @change="changeBtn">
                            <el-radio-button v-for="(state,index) in states"  :key="index" :label="state">{{state}}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="nav_item">
                        <span class="cont_title">月份：</span>
                        <el-radio-group  v-model="month" @change="changeBtn">
                            <el-radio-button 
                            v-for="(month,index) in months"  
                            :key="index" :label="month" 
                            :disabled="year=='2015'&&month.slice(0,2)<9?true:false"
                            >{{month!='全部' ? month+'月' : month}}</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="event_main clearfix">
                <div class="event_lists f_left">
                    <div class="lists_title">赛事列表</div>
                    <ul class="lists">
                        <template v-if="eventlists.length">
                            <li class="list_event clearfix" 
                            v-for="(item,index) in eventlists" 
                            :key="index"
                            @click="goEventdetail(item.id)">
                                <img class="event_img f_left" :src="item.images" :alt="item.name">
                                <div class="event_info f_left">
                                    <div class="info_head">
                                        <h3 class="title">{{item.title | filterStrlen}}</h3>
                                        <button
                                        v-if="item.follow" 
                                        class="followed" 
                                        @click.stop="followed(index,item.id,item)">已关注</button>
                                        <button v-else class="unfollowed"  @click.stop="followed(index,item.id,item)">+关注</button>
                                    </div>
                                    <div v-if="!item.isEnroll">
                                    <template>
                                        <span class="farTime">{{item.labels}}</span>
                                    </template>
                                    </div>
                                    <div v-else>
                                     <template  v-if="item.status==-1">
                                        <span class="farTime">赛事已关闭</span>
                                    </template>
                                    <template  v-else-if="item.status==0">
                                        <span class="farTime">距报名开始：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==1">
                                        <span class="farTime">距报名结束：{{time[index]}}</span>
                                    </template>
                                     <template  v-else-if="item.status==2">
                                        <span class="farTime">报名已结束</span>
                                    </template>
                                    <template  v-else-if="item.status==3">
                                        <span class="farTime">即将开赛：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==4">
                                        <span class="farTime">距赛事结束：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==5">
                                        <span class="farTime">赛事已完成</span>
                                    </template>
                                    </div>
                                    <div class="info_bottom clearfix">
                                        <span class="begin_time f_left">竞赛时间：{{item.matchStartDate|dealTime}}</span> 
                                        <div v-if="!item.isEnroll">
                                    <template >
                                            <span class="farTime">
                                                <button class="apply f_right notapply" disabled>{{item.labels}}</button>
                                            </span>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <template  v-if="item.status==0">
                                            <span class="farTime">
                                                <button class="apply f_right notapply" disabled>报名未开始</button>
                                            </span>
                                        </template>
                                        <template  v-else-if="item.status==1">
                                            <span class="farTime">
                                                <button class="apply f_right">立即报名</button>
                                            </span>
                                        </template>
                                        <template  v-else-if="item.status==2">
                                            <span class="farTime">
                                                <button class="apply f_right notapply" disabled>报名已结束</button>
                                            </span>
                                        </template>
                                        <template  v-else-if="item.status==3">
                                            <span class="farTime">
                                                <button class="apply f_right" disabled>即将开赛</button>
                                            </span>
                                        </template>
                                        <template  v-else-if="item.status==4">
                                            <span class="farTime">
                                                <button class="apply f_right notapply" disabled>赛事已开始</button>
                                            </span>
                                        </template>
                                        <template  v-else-if="item.status==5">
                                            <span class="farTime">
                                                <button class="apply f_right notapply" disabled>赛事已完成</button>
                                            </span>
                                        </template>
                                         </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else>
                            <li class="nolist">
                                <img src="../../assets/icons/event_not.png" alt="">
                                <span>当前没有符合您要求的赛事哦~</span>       
                            </li>
                        </template>
                    </ul>
                    <div class="pages" v-if="eventlists.length">
                        <el-pagination
                        background
                        @current-change="pageChange"
                        layout="prev,pager,next"
                        :total="totalnum"
                        :current-page="page"
                        >
                        </el-pagination>
                    </div>   
                </div>
                <div class="event_recommend f_right">
                    <div class="recommed_title">推荐赛事</div>
                    <ul class="recommed_lists">
                        <template v-if="recommends.length">
                            <li class="recommed_list" 
                            v-for="(item,index) in recommends" 
                            :key="index"
                            @click="goEventdetail(item.id)">
                                <img :src="item.images|dealEventimg"  class="recommed_list_img" alt="图片资源找不到">
                                <span class="recommed_list_title">
                                    {{item.title}}
                                </span>
                                <span class="recommed_list_date">{{item.area}} {{item.matchStartDate|dealTime2}}</span>
                            </li>
                        </template>
                        <template v-else>
                            <li>
                                <span>当前没有符合您要求的赛事哦~</span>       
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <Footer :footerBgColor="'#fafafa'" ref ="footer"></Footer>
    </div>
</template>

<script>
// import Header from '../../components/Header/header';
// import Footer from '../../components/Footer/footer';
import {event,keyword,Like} from '../../config/request';
import {formatDuring,getDate} from '../../config/utils';
import {provinces} from '../../plugins/city';
export default {
    components:{
        // Header,
        // Footer
    },
    filters: {
        // 竞赛时间截取前十个字符年月日
        dealTime(v){
            var data = v.length>10 ? v.substr(0,10)+'' : v;
            var year = data.split('-')[0];
            var month = data.split('-')[1];
            var date = data.split('-')[2];
            return year+'年'+month+'月'+date+'日';
        },
        //时间截取5到10的字符
        dealTime2(v) {
            var data = v.length>10 ? v.substr(5,5)+'' : v;
            var month = data.split('-')[0];
            var date = data.split('-')[1];
            return month+'月'+date+'日';
            // return v.substr(5,6);
        },
        // 标题只取前15个字符
        filterStrlen(str) {
            return str.length>15 ? str.substr(0,15)+'...' : str;
        },
         // 赛事图片处理
        dealEventimg(v) {
            if(v.indexOf('http') == 0) {
                return v;
            }else {
                return v.substr(v.indexOf('http'));
            }
        }
    },
    computed: {
    },
    data() {
        return {
            title:'11111111111111111111111111',
            area:'全部',//选择的区域
            year:'全部',//选择的年份
            month:'全部',//选择的月份
            type:'全部',//选择的赛事类型
            state:'全部',//选择的赛事状态
            areas:['全部','北京','上海','广州','深圳','杭州'],//区域列表
            types:['全部','全马','半马','迷你','越野','其他'],//赛事项目
            years:['全部','2023','2022','2021','2020','2019'],
            states:['全部','报名未开始','报名进行中','即将开赛','赛事进行中','赛事已完成'],
            months:['全部','01','02','03','04','05','06','07','08','09','10','11','12'],
            isfollow:false,//是否关注
            follow_index:'',
            keywords:'',//搜索关键词
            isHotkey:true,//是否显示热门关键字轮播
            hotKey:'',//当前的热门关键字
            hotKeywords:[],//热门搜索词数组
            isSlide:false,//区域是否展开
            eventlists:[],//赛事列表
            recommends:[],//推荐赛事
            time:[],//倒计时数组
            recommendTime:[],//推荐赛事时间日期数组
            totalnum:0,//赛事列表总数量
            page:1,//页码
            timehandle:null,
            isLoading:true
        }
    },
    methods: {
        //展开
        slideDown() {
            this.isSlide =!this.isSlide;
        },
        // 关注
        followed(index,id,item) {
            if(!sessionStorage.getItem('userInfo')) {
                // 弹出登录框
                this.$refs.Header.Login();
            }else {
                this.follow_index = index;
                this.isfollow = true;
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                let accountId = userInfo.id
                Like({
                    type:'MATCH',
                    id:id,
                    // accountId:accountId
                }).then(res=> {
                    console.log(res)
                    // this.getEventlists()
                    // 关注成功
                    item.follow=!item.follow
                    // if(res.code ==1 &&res.objectData ==2) {
                    //     item.iscollection = 1;
                    // }else if(res.code ==1 &&res.objectData ==1) {
                    //     //取关
                    //     item.iscollection = 0;
                    // }
                })
            }
        },
        // page页码发生改变时
        pageChange(page) {
            // console.log(page)
            this.page = page;
            this.getEventlists()
        },
        //获取赛事列表
        getEventlists() {
            // 获取此时的关键词
            let name = sessionStorage.getItem('keywords') == ''?'':sessionStorage.getItem('keywords');
            let area = this.area == '全部' ?'':this.area;
            let entryType =  this.type == '全部' ?'':this.type;
            let other = this.type == '其他' ? '1' : '';
            let year = this.year == '全部' ?'':this.year;
            let status = this.state == '全部' ?'':this.state=='报名未开始'?'0':this.state=='报名进行中'?'1':this.state=='报名已结束'?'2':this.state=='即将开赛'?'3':this.state=='赛事进行中'?'4':'5';
            let month = this.month == '全部' ?'':this.month;
            let accountid;
            this.isLoading = true;
            if(sessionStorage.getItem('userInfo')) {
                accountid = JSON.parse(sessionStorage.getItem('userInfo')).id
            }else {
                accountid=''
            }
            event.lists({
                primary:name,
                pca:area,//区域
                entryType:entryType,//项目
                year:year,//年份
                status:status,//赛况
                month:month,//月份
                rows:this.page,
                size:10
                // accountid:accountid,
                // other:other
            }).then(res => {
                console.log(res)
                this.isLoading = false;
                if(res.code==200 && res.data) {
                    res.data=this.$DateFormatimg(res.data)
                    res.data.forEach(data =>{
                         data.isEnroll = true;
						// console.log('map',item);
						var canlder = (data.state & 0XF0);
						var expire = (data.state & 0X04);
						var cancel = (data.state & 0X01);
						console.log(data.state);
						if (canlder == 16) {
							// data.isEnroll = false;
                            this.$set(data,'isEnroll',false)
						}
						if (cancel == 1 || expire == 4) {
							if (cancel == 1) {
								data.labels = "赛事已取消";
                                // this.$set(data,'lables','赛事已取消')
							} else {
								data.labels = "赛事已延期";
                                // this.$set(data,'lables','赛事已延期')
							}
						data.isEnroll = false;
                        //  this.$set(data,'isEnroll',false)
						}
                    })
                   
                    this.eventlists = res.data;
                    this.totalnum = res.total;
                    console.log(this.eventlists)
                    this.$forceUpdate()
                    this.countdown();
                     console.log(this.eventlists)
                }
            })

        },
        //获取推荐赛事
        getRecommends() {
            event.recommends().then(res => {
                // console.log(res)
                if(res.code == 200 && res.data.length) {
                    res.data=this.$DateFormatimg(res.data)
                    this.recommends = res.data;
                    // let timeLists = []
                    // for(let i=0;i<this.recommends.length;i++) {
                    //     let time = getDate(this.recommends[i].matchStartDate)
                    //     timeLists.push(time)
                    //     // console.log(timeLists)
                    // }
                    // this.recommendTime = timeLists;
                }
            })
        },
        //时间倒计时
        countdown() {
             window.clearTimeout(this.timehandle)
            this.timehandle = setTimeout(()=> {
                let timeCur = [];
                let array = this.eventlists;
                for (let i = 0; i < array.length; i++) {
                    var name = "";
                    var curTime = new Date(array[i].currentTime).getTime()+1000;
                        this.$set(array[i],'currentTime',curTime)
                    if (array[i].status == 0) {
                        var times = Date.parse(new Date(array[i].enrollStartDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                        name = formatDuring(sub);
                    }else if (array[i].status == 1) {
                        // var curTime = new Date(array[i].currentTime).getTime()+1000;
                        var times = Date.parse(new Date(array[i].enrollEndDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                        name = formatDuring(sub);
                    } else if (array[i].status == 3) {
                        // var curTime = new Date(array[i].currentTime).getTime()+1000;
                        var times = Date.parse(new Date(array[i].matchStartDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                        name = formatDuring(sub);
                    } else if (array[i].status == 4) {
                        var curTime = new Date(array[i].currentTime).getTime()+1000;
                        var times = Date.parse(new Date(array[i].matchEndDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        name = formatDuring(sub);
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                    }
                    timeCur.push(name);
                }
               
                this.time = timeCur;
                // console.log(this.time)
                this.countdown();
            }, 1000)
        },
        //点击搜索根据关键字搜索赛事列表
        searchList() {
            // 不输入按照当前热门关键字来搜索，输入就输入框中的字搜索
            this.isHotkey?sessionStorage.setItem('keywords',this.hotKey):sessionStorage.setItem('keywords',this.keywords);
            this.getEventlists();
        },
        //获取热门搜索词
        getKeyword() {
        //     keyword({
        //         type:0,
        //         pageNo:1
        //     }).then(res =>{
        //         console.log(res)
        //         if(res.code == 1 && res.list.length) {
        //             let index = Math.floor(Math.random()*5)
        //             // this.keywords = res.list[index].keywords;
        //             this.hotKeywords = res.list;
        //         }else {
        //             this.isHotkey = false;
        //         }
        //     })
        },
        //输入时不显示关键词轮播
        getkey() {
            this.isHotkey = false;
        },
        //点击热门关键字时
        clickHotkey() {
            $('.search_ipt')[0].focus();
        },
        //轮播改变时获取当前的搜索词
        changehotkey(v) {
            this.hotKey = $('.key')[v].innerHTML;
            // console.log(this.hotKey)
        },
        //筛选按钮改变时
        changeBtn(v) {
            this.page = 1;
            this.getEventlists();
        },  
        // 区域列表
        getArea() {
            const newAreas =  provinces.filter(area => !this.areas.includes(area));
            // console.log(newAreas)
            this.areas=[...this.areas,...newAreas];
        },
        // 跳到指定赛事详情页
        goEventdetail(eventId) {
            console.log(eventId);
            this.$router.push({
                path:'/eventDetail',
                query:{
                    eventId:eventId,
                    html:true
                }
            })
        },
         // 弹出登录弹框
        isLogined() {
            this.$store.commit('updateLogin',true)
        },
        // 监听滚动并将推荐赛事悬浮
        scrollHandle() {
            $(window).scroll(()=> {
                if ($(window).scrollTop() >= $('.event_top').innerHeight() + 102) {
                    $('.event_recommend').addClass('fix');
                    var wScrollY = window.scrollY; // 当前滚动条位置
                    var wInnerH = window.innerHeight; // 设备窗口的高度（不会变）
                    var bScrollH = document.body.scrollHeight; // 滚动条总高度 
                    if (wScrollY + wInnerH >= bScrollH-213) {
                        $('.event_recommend').addClass('slide');
                        $('.event_recommend').removeClass('fix');
                    }else {
                        $('.event_recommend').removeClass('slide');
                        $('.event_recommend').addClass('fix');
                    }
                }else {
                    $('.event_recommend').removeClass('fix');
                }
            });
        }
    },
    mounted() {
        sessionStorage.removeItem('keywords');
        this.getEventlists();
        this.getArea();
        this.getKeyword();
        this.getRecommends();
        this.scrollHandle();
    },
    destroyed() {
        window.clearTimeout(this.timehandle)
    }
}
</script>

<style>
@import './style/event.css'
</style>
